<script setup></script>
<template>
  <div>
    <div class="pb-12 bg-inherit">
      <div class="relative">
        <div class="absolute inset-0 h-2/3 bg-inherit"></div>
        <div class="relative mx-auto">
          <div class="lg:max-w-6xl lg:mx-auto">
            <NuxtImg
              provider="twicpics"
              class="transform scale-110 select-none pointer-events-none"
              src="/new-dashboard.webp"
              alt="dashboard overview"
              data-not-lazy
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
/* .perspective {
  perspective: 1000px;
}

@media (min-width: 768px) {
  .transform {
    transform: rotateX(20deg);
  }
} */
</style>
